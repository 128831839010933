





















































import { AnyObject } from '@movecloser/front-core'
import { Component } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { AllowedImageRatio } from '../../../contexts'

import { AbstractModuleUi } from '../../abstract/ui'
import { BenefitsBar } from '../../../front/shared/molecules/BenefitsBar'
import { toImageProps } from '../../../front/shared/support'
import { Link } from '../../../dsl/atoms/Link/Link.vue'
import { LinkProps } from '../../../dsl/atoms/Link'
import { Carousel as UiCarousel } from '../../../dsl/molecules/Carousel/Carousel.vue'

import {
  AddonType,
  HeroModule,
  Slide
} from '../Hero.contracts'

import {
  HERO_COMPONENT_KEY,
  HERO_DEFAULT_CONFIG,
  heroCarouselConfig,
  heroModuleAddonsUiRegistry
} from './Hero.ui.config'

/**
 * Container component for the `HeroModuleUi`.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<HeroModuleUi>({
  name: 'HeroModuleUi',
  components: { BenefitsBar },
  created (): void {
    this.config = this.getComponentConfig(HERO_COMPONENT_KEY, HERO_DEFAULT_CONFIG)
  }
})
export class HeroModuleUi extends AbstractModuleUi<HeroModule> {
  /**
   * Determines active slide index.
   */
  public activeSlideIndex = 0

  /**
   * Determines the content color of the
   */
  public contentColor (slide: Slide): string {
    return slide.contentColor ? slide.contentColor : 'default'
  }

  /**
   * Determines whether `Hero` has benefits bar.
   */
  public get hasBenefitsBar (): boolean {
    return this.getConfigProperty('hasBenefitsBar')
  }

  public get linkTheme (): boolean {
    return this.getConfigProperty('linkTheme')
  }

  public get linkDecorator (): boolean {
    return this.getConfigProperty('linkDecorator')
  }

  public get delay (): boolean {
    return this.getConfigProperty('delay')
  }

  public get autoplay (): boolean {
    return this.getConfigProperty('autoplay')
  }

  /**
   * Determines is creations products should render
   */
  public get shouldRender (): boolean {
    return this.content.slides.length > 0
  }

  public get wrapperComponent (): VueConstructor | string {
    return this.content.slides.length > 1 ? UiCarousel : 'div'
  }

  public get wrapperProps (): AnyObject | null {
    if (this.content.slides.length < 1) {
      return null
    }
    return { ...heroCarouselConfig, autoplay: this.content.isBanner ?? this.autoplay, delay: this.delay }
  }

  /**
   * Determines slide addon module
   */
  public getModule (type: AddonType): VueConstructor {
    return heroModuleAddonsUiRegistry[type]
  }

  public getSlideComponent (slide: Slide): string | VueConstructor {
    return slide.imageAsLink ? Link : 'div'
  }

  public getSlideProps (slide: Slide): LinkProps | null {
    return slide.imageAsLink ? (slide.link ?? null) : null
  }

  /**
   * Determines is hero slide has addon.
   */
  public hasAddon (slide: Slide) {
    return typeof slide.addon !== 'undefined' && !!slide.addon.type && !!slide.addon.content
  }

  /**
   * Carousel slide change event handle
   */
  public onSlideChange (activeSlideIndex: number) {
    this.activeSlideIndex = activeSlideIndex
  }

  /**
   * Determines slide styles
   */
  public backgroundImg (slide: Slide) {
    if (!slide) {
      return
    }

    if (!slide.background) {
      // @FIXME
      return { src: '', alt: '' }
    }

    return toImageProps(slide.background, AllowedImageRatio.Original)
  }

  /**
   * Determines slide styles
   */
  public videoUrl (slide: Slide): string | undefined {
    if (!slide) {
      return
    }

    if (!slide.video) {
      return
    }

    return slide.video.url
  }
}

export default HeroModuleUi
